import { useAuth0 } from '@auth0/auth0-react';
import { GlobalHeader } from '@deepup/global-header';
import Box from '@mui/material/Box';
import { useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { NavigationSidebar } from '@components/NavigationSidebar';

export const Layout: FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (location.pathname === '/' && isAuthenticated) {
      // common entrypoint for all admins (platform and organization).
      // So for now opinionated on this from app perspective
      navigate('/projects');
    }
  }, [navigate, isAuthenticated]);

  return (
    <>
      {isAuthenticated && (
        <GlobalHeader
          guideConfig={{ appIdentifier: 'organizationManagement' }}
          language={i18n.language as 'de' | 'en' | undefined}
          languageSelectors={[{ key: 'de' }, { key: 'en' }]}
          onChangeLanguage={(selectedLanguage) => {
            i18n.changeLanguage(selectedLanguage);
          }}
          showLanguageToggle
          showThemeToggle
          title="Organization Management"
        />
      )}
      <Box display="flex" flex={1}>
        <NavigationSidebar />
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={2}
          paddingLeft={45}
          paddingRight={8}
          paddingY={13}
          width="100%"
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
